$backgroundColor: #d71494;
$buttonColor: #f3acf4b3;
$textColor: #f1ce64;
$hoverColor: #fff;

.Footer {
  background: linear-gradient(
    91.93deg,
    rgba(243, 172, 244, 0.702) 7.44%,
    rgba(181, 215, 248, 0.7) 94.63%
  );
  box-shadow: 0 1px 10px;
  height: 120px;
  margin-top: 28px;
}

#logo_footer {
  width: 60%;
}

.share {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  padding: 15px;
  position: relative;
  border-radius: 50%;
  height: 100%;
  box-sizing: border-box;

  .nav_footer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    padding: 15px;
    position: absolute;
    top: 0;
    color: $textColor;
    opacity: 0;
    pointer-events: none;
    transition: all 0.3s ease-in-out;

    &.footer_display {
      opacity: 1;
      pointer-events: auto;
    }
  }
}

.footer_a {
  height: 45px;
  width: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  color: $buttonColor;
  transition: all 0.2s ease-in-out;
  margin: 0 5px;
  box-shadow: 3px 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 50%;

  &:nth-child(1) {
    margin-left: 0;

    &:hover {
      background-color: $buttonColor;
    }
  }

  &:nth-child(2):hover {
    background-color: $buttonColor;
  }

  &:nth-child(3):hover {
    background-color: $buttonColor;
  }

  &:nth-child(4):hover {
    background-color: $buttonColor;
  }

  &:nth-child(5):hover {
    background-color: $buttonColor;
  }

  &:nth-child(6):hover {
    background-color: $buttonColor;
  }

  &:nth-child(7):hover {
    background-color: $buttonColor;
  }

  &:nth-child(8) {
    margin-right: 0;

    &:hover {
      background-color: $buttonColor;
    }
  }

  &:nth-child(9) {
    margin-right: 0;

    &:hover {
      background-color: $buttonColor;
    }
  }
}

.button_fb {
  transition: 0.5s ease;
}

.button_fb:hover {
  transform: scale(1.09);
}

.button_share,
.button_fb {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  opacity: 1;
  background-color: $backgroundColor;
  border-radius: 50%;
}

@media screen and (min-width: 768px) {
  .footer_a {
    height: 60px;
    width: 60px;
    margin: 0 5px;
  }
}

@media screen and (min-width: 1024px) {
  .button_share,
  .button_fb {
    height: 70px;
    width: 70px;
  }

  .footer_a {
    height: 70px;
    width: 70px;
    margin: 0 5px;
  }
}

@media screen and (min-width: 1440px) {
  .footer_a {
    margin: 0 15px;
  }
}
