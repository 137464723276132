.burger-menu {
  cursor: pointer;
  border-color: transparent;
  background-color: transparent;
  margin-right: 10px;
}

.burger-menu > span {
  display: block;
  position: relative;
  z-index: 1500;
  height: 5px;
  width: 31px;
  border-radius: 5px;
  background-color: var(--purple-color);
  margin: 6px;
}

.burger-menu span:nth-child(1) {
  height: 5px;
  width: 31px;
  border-radius: 5px;
  background-color: var(--purple-color);
  transition: 0.3s;
  transform-origin: top right;
}

.burger-menu span:nth-child(3) {
  height: 5px;
  width: 31px;
  border-radius: 5px;
  background-color: var(--purple-color);
  transform-origin: bottom right;
  transition: 0.3s;
}

#home.is-open .burger-menu > span:nth-child(2) {
  animation: fadeOutRight 0.3s both;
}

#home.is-open .burger-menu span:nth-child(1) {
  transform: rotate(-45deg);
}

#home.is-open .burger-menu span:nth-child(3) {
  margin-top: -2px;
  transform: rotate(45deg);
}

@keyframes fadeOutRight {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(60px);
    opacity: 0;
  }
}