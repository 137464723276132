.login {
  min-height: 75vh;
  background-image: url(../../assets/Accueil/logo-background.png);
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: 100%;
}

.login-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin: 50px auto 0 auto;
  width: 80vw;
  max-width: 400px;
  height: 250px;
  background: linear-gradient(
    91.93deg,
    rgba(243, 172, 244, 0.7) 7.44%,
    rgba(181, 215, 248, 0.7) 94.63%
  );
  border-radius: 25px;
}

.login-form input {
  width: 70vw;
  max-width: 350px;
  height: 40px;
  font-family: 'Itim', sans-serif;
  border-radius: 25px;
  border-style: none;
  margin-top: 20px;
  padding-left: 10px;
  box-sizing: border-box;
}

.login-form input:focus {
  outline: none;
  border: 2px solid var(--purple-color);
}

.button-login {
  background-color: var(--purple-color);
  border-radius: 25px;
  font-family: 'Itim', sans-serif;
  color: white;
  width: 100px;
  height: 40px;
  border-style: none;
  margin-top: 30px;
  transition: 0.5s ease;
}

.button-login:hover {
  transform: scale(1.09);
}

@media screen and (min-width: 768px) {
  .login {
    background-size: 100%;
  }

  .login-container {
    margin: 120px auto 0px auto;
  }
}

@media screen and (min-width: 1024px) {
  .login {
    background-size: 80%;
  }
}

@media screen and (min-width: 1440px) {
  .login {
    background-size: 70%;
  }
}
