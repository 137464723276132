.container_picture_show {
  display: flex;
  justify-content: center;
}

.picture_show {
  width: 70vw;
  border-radius: 15px;
  max-height: 400px;
  object-fit: cover;
  object-position: top;
}

.text_show {
  margin: 10px;
}

.list_show li {
  padding: 10px;
}

@media screen and (min-width: 768px) {
  .text_show {
    margin-top: 5px;
  }

  .picture_show {
    width: 55vw;
  }
}

@media screen and (min-width: 1024px) {
  .list_show li {
    padding: 15px;
  }

  .picture_show {
    width: 50vw;
  }
}

@media screen and (min-width: 1440px) {
  .picture_show {
    width: 40vw;
  }

  .text_show {
    margin-top: 10px;
  }
}

@media screen and (min-width: 1900px) {
  .picture_show {
    width: 35vw;
  }
}
